import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-951748fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "url_modal-title" }
const _hoisted_2 = { class: "url_modal-info" }
const _hoisted_3 = { class: "url_modal-input" }
const _hoisted_4 = { class: "url_modal-btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_CopyOutlined = _resolveComponent("CopyOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    class: "url_modal",
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isVisible) = $event)),
    footer: null
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.info), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_textarea, {
          class: "url_modal-input-input",
          value: _ctx.value,
          autosize: { minRows: 1, maxRows: 2 }
        }, null, 8, ["value"]),
        _createVNode(_component_a_tooltip, { title: _ctx.tip }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "text",
              onClick: _ctx.copy
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_CopyOutlined, { style: {"font-size":"24px"} })
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_a_button, {
          type: "primary",
          shape: "round",
          ghost: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isVisible = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode("關閉")
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}