
import { apiPost } from "@/lib/api_post";
import {
  PostListItem,
  MyPostStatusList,
  SearchScopeList,
  SearchScope,
  CreatorInfo,
} from "@/models/post";
import { userStore } from "@/store/user";
import {
  defineComponent,
  reactive,
  Ref,
  ref,
  onMounted,
  toRefs,
  watch,
  computed,
} from "vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import { ColumnsType } from "ant-design-vue/lib/table";
import dayjs, { Dayjs } from "dayjs";
import LadySelector from "@/components/LadySelector.vue";
import { PostStatus } from "@lctech-tw/post-proto/dist/js/external/enums/enums_pb";
import { isDesktop } from "@/lib/utils";
import { useRouter } from "vue-router";
import CopyModal from "@/components/CopyModal.vue";
import { postStore } from "@/store/post";
interface Range {
  name: string;
  from: Dayjs;
  to: Dayjs;
}
export default defineComponent({
  name: "ViewJobList",
  components: {
    SearchOutlined,
    LadySelector,
    CopyModal,
  },
  setup() {
    const router = useRouter();
    const columns: ColumnsType = [
      {
        title: "編號",
        dataIndex: "orderNumber",
      },
      {
        title: "通告名稱",
        dataIndex: "name",
      },
      {
        title: "通告類型/品項名",
        dataIndex: ["type", "name"],
      },
      {
        title: "通告時間",
        dataIndex: "time",
      },
      {
        title: "狀態",
        dataIndex: "status",
      },
      {
        title: "建立者",
        dataIndex: "creator",
      },
      {
        title: "非公開",
        dataIndex: "isPublic",
      },
      {
        title: "操作",
        key: "action",
      },
    ];
    const searchParams = postStore.searchParams;
    const state = reactive({
      isLoading: false,
      isDesktop: isDesktop(),
      isModalVisible: false,
    });

    const ranges: Range[] = [
      {
        name: "本週通告",
        from: dayjs().startOf("week"),
        to: dayjs().endOf("week"),
      },
      {
        name: "本月通告",
        from: dayjs().startOf("month"),
        to: dayjs().endOf("month"),
      },
      {
        name: "上月通告",
        from: dayjs().subtract(1, "month").startOf("month"),
        to: dayjs().subtract(1, "month").endOf("month"),
      },
    ];

    const postList: Ref<PostListItem[]> = ref([]);
    const getPostList = async (page: number) => {
      state.isLoading = true;
      const res = await apiPost.getPostList(
        {
          page: page,
          limit: searchParams.limit,
          publishType: searchParams.isPublic,
          status: searchParams.status,
          from:
            searchParams.searchScope === SearchScope.range
              ? searchParams.from?.toTimestamp()
              : undefined,
          to:
            searchParams.searchScope === SearchScope.range
              ? searchParams.to?.toTimestamp()
              : undefined,
          postNumber:
            searchParams.searchScope === SearchScope.order
              ? searchParams.search
              : undefined,
          creator:
            searchParams.searchScope === SearchScope.creator
              ? new CreatorInfo({ name: searchParams.search }).toCreator()
              : undefined,
          starObjectIds: [SearchScope.name, SearchScope.uid].includes(
            searchParams.searchScope
          )
            ? searchParams.objectIds.flat()
            : undefined,
        },
        userStore.ginToken.value
      );
      searchParams.page = page;
      postList.value = res.getInfosList().map((e) => new PostListItem(e));
      searchParams.total = res.getTotal();
      state.isLoading = false;
    };
    onMounted(() => {
      getPostList(searchParams.page);
    });

    const onTableChange = async (
      pagination: any,
      filters: any,
      sorter: { field: string; order: "ascend" | "descend" }
    ) => {
      await getPostList(pagination.current);
    };

    const isRange = (range: Range) => {
      return (
        searchParams.from?.unix() === range.from.unix() &&
        searchParams.to?.unix() === range.to.unix() &&
        searchParams.searchScope === SearchScope.range
      );
    };

    const setRange = (range: Range) => {
      searchParams.searchScope = SearchScope.range;
      if (isRange(range)) {
        searchParams.from = null;
        searchParams.to = null;
        searchParams.range = [null, null];
      } else {
        searchParams.from = range.from;
        searchParams.to = range.to;
        searchParams.range = [range.from, range.to];
      }

      getPostList(1);
    };

    const updateObjectIds = (ids: string[]) => {
      searchParams.objectIds = ids;
    };

    const handleRangeChange = (val: [Dayjs, Dayjs] | null) => {
      console.log(val);
      if (val === null) {
        searchParams.from = null;
        searchParams.to = null;
      } else {
        searchParams.from = val[0].startOf("day");
        searchParams.to = val[1].endOf("day");
      }
    };

    const togglePost = async (record: PostListItem) => {
      state.isLoading = true;
      try {
        const res = await apiPost.setPostStatus(
          {
            postId: record.id,
            status: record.isActivated ? PostStatus.ENROLL : PostStatus.CLOSE,
          },
          userStore.ginToken.value
        );
        record.setStatus(
          record.isActivated ? PostStatus.ENROLL : PostStatus.CLOSE
        );
      } catch (error) {
        // 回溯
        record.setStatus(
          record.isActivated ? PostStatus.CLOSE : PostStatus.ENROLL
        );
      }

      state.isLoading = false;
    };
    const toSettlement = async (record: PostListItem) => {
      state.isLoading = true;
      try {
        if (!record.isOnSettle) {
          const yes = confirm("確定結算？");
          if (yes) {
            const res = await apiPost.setPostStatus(
              {
                postId: record.id,
                status: PostStatus.SETTLEMENT,
              },
              userStore.ginToken.value
            );
            record.setStatus(PostStatus.SETTLEMENT);
          } else {
            state.isLoading = false;
            return;
          }
        }
        router.push({ name: "postSettle", params: { id: record.id } });
      } catch (error) {
        console.error("toSettlement error: ", error);
      }

      state.isLoading = false;
    };

    const pagination = computed(() => {
      return {
        current: searchParams.page,
        pageSize: searchParams.limit,
        total: searchParams.total,
      };
    });

    const createCheckInLink = async (record: PostListItem) => {
      const token = await apiPost
        .createScanToken({ postId: record.id }, userStore.ginToken.value)
        .then((res) => res.getToken());
      checkInUrl.value = `${window.location.origin}/checkin/${record.id}?token=${token}`;
      state.isModalVisible = true;
    };
    const checkInUrl = ref("");
    return {
      ...toRefs(state),
      postList,
      getPostList,
      SearchScopeList,
      MyPostStatusList,
      searchParams: toRefs(searchParams),
      onTableChange,
      columns,
      ranges,
      isRange,
      setRange,
      SearchScope,
      updateObjectIds,
      handleRangeChange,
      togglePost,
      pagination,
      toSettlement,
      createCheckInLink,
      checkInUrl,
    };
  },
});
