
import { defineComponent, reactive, toRefs, watch } from "vue";
import { debounce } from "lodash";
import { apiCompany } from "@/lib/api_gin";
import { userStore } from "@/store/user";
interface Lady {
  uid: number;
  objectId: string;
  name: string;
}
export default defineComponent({
  name: "LadySelector",
  emits: ["update:objectIds"],
  props: {
    isUidScope: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const __state: {
      ladyList: Lady[];
      isFetching: boolean;
    } = {
      ladyList: [],
      isFetching: false,
    };
    const state = reactive(__state);

    const fetchLadyList = debounce(async (search: string) => {
      state.ladyList = [];
      if (search) {
        state.isFetching = true;
        const res = await apiCompany.getStarList(
          {
            page: 1,
            limit: 1000,
            name: props.isUidScope ? undefined : search,
            uid: props.isUidScope ? Number(search) : undefined,
            status: true,
            isMine: false,
          },
          userStore.ginToken.value
        );
        state.ladyList = res.getDataList().map((e) => {
          return {
            uid: e.getUid(),
            objectId: e.getObjectId(),
            name: e.getDisplayName(),
          };
        });
        state.isFetching = false;
      }
    }, 300);

    const handleChange = (ids: string[]) => {
      emit("update:objectIds", ids);
    };

    return {
      ...toRefs(state),
      fetchLadyList,
      handleChange,
    };
  },
});
