import { SearchScope } from "@/models/post";
import { PostStatus } from "@lctech-tw/post-proto/dist/js/external/enums/enums_pb";
import { Dayjs } from "dayjs";
import { reactive } from "vue";
import { isDesktop } from "@/lib/utils";
type RangeValue = [Dayjs | null, Dayjs | null];
const __searchParams: {
  searchScope: SearchScope;
  search: string;
  isPublic: null | boolean;
  status: PostStatus;
  page: number;
  limit: number;
  total: number;
  from: Dayjs | null;
  to: Dayjs | null;
  objectIds: string[];
  range: RangeValue;
} = {
  searchScope: SearchScope.name,
  search: "",
  isPublic: null,
  status: isDesktop() ? PostStatus.ALLSTATUS : PostStatus.CHECKIN,
  from: null,
  to: null,
  objectIds: [],
  page: 1,
  limit: 10,
  total: 0,
  range: [null, null],
};
const searchParams = reactive(__searchParams);

export const postStore = {
  searchParams,
};
