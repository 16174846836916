
import { Toast } from "@/lib/toast";
import { copyTextToClipboard } from "@/lib/utils";
import { defineComponent, toRefs, reactive, onMounted, watch } from "vue";
import Icon, { CopyOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  name: "CopyModal",
  components: { CopyOutlined },
  props: {
    title: { default: "", type: String },
    info: { default: "", type: String },
    tip: { default: "複製", type: String },
    isRemoteVisible: { required: true, type: Boolean },
    value: { required: true, type: String },
  },
  setup(props) {
    const state = reactive({
      isVisible: false,
    });
    const copy = () => {
      copyTextToClipboard(props.value);
      Toast.info("連結已複製");
    };
    onMounted(() => {
      state.isVisible = props.isRemoteVisible;
    });
    watch(
      () => props.isRemoteVisible,
      (val, oldVal) => {
        if (val !== oldVal) {
          state.isVisible = val;
        }
      },
      { immediate: true }
    );
    return { ...toRefs(props), ...toRefs(state), copy };
  },
});
